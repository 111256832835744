export const checkStatus = () => {
    // Obtém a data e hora atual em UTC
    var dataAtual = new Date();

    var options = { weekday: 'long', timeZone: 'America/Sao_Paulo' };

    var diaDaSemana = dataAtual.toLocaleString('pt-BR', options);

    // Converte a hora para o fuso horário de Brasília (UTC-3)
    dataAtual.setUTCHours(dataAtual.getUTCHours());

    // Obtém a hora atual em Brasília como uma string no formato HH:mm
    var horaAtualBrasilia = dataAtual.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit' });


    // Verifica se a hora está entre 12:00 e 02:00
    
    if (['sexta-feira', 'sábado'].includes(diaDaSemana)) {
        return horaAtualBrasilia >= '15:00' && horaAtualBrasilia <= '23:59' || horaAtualBrasilia >= '00:00' && horaAtualBrasilia <= '06:00';
    } else {
        return horaAtualBrasilia >= '15:00' && horaAtualBrasilia <= '23:59' || horaAtualBrasilia >= '00:00' && horaAtualBrasilia <= '03:00';
    }
    
    // if (diaDaSemana == 'sexta-feira' || diaDaSemana == 'sabado') {
    //     if (horaAtualBrasilia >= '15:00' && horaAtualBrasilia <= '23:59' || horaAtualBrasilia >= '00:00' && horaAtualBrasilia <= '04:00') {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // } else {
    //     if (horaAtualBrasilia >= '15:00' && horaAtualBrasilia <= '23:59' || horaAtualBrasilia >= '00:00' && horaAtualBrasilia <= '03:00') {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }
};