import { createStore } from 'vuex'

export default createStore({
  state: {
    activePage: 'cardapio',
  },
  getters: {
    activePage: (state) => state.activePage,
  },
  mutations: {
    SET_ACTIVE_PAGE(state, page) {
      state.activePage = page;
    },
  },
  actions: {
  },
  modules: {
  }
})
