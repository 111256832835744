<template>
    <div id="manutencao" class="container d-flex justify-content-center flex-column text-center vh-100 align-items-center">
        <div class="col-md-12 text-secondary">
            <h4>Cardápio em manutenção</h4>
        </div>

        <div class="col-md-12">
            <h3 class="text-success">
                Poderá realizar seu pedido pelo ifood

            </h3>

            <a class="fs-3 d-flex flex-column align-items-center justify-content-center" href="https://www.ifood.com.br/delivery/uberlandia-mg/rei-do-pao-de-alho-presidente-roosevelt/a2b38159-0617-4230-adbf-3086f82571c7" target="_blank">
                <img :src="require('@/assets/img/outros/ifood.png')" style="width: 200px" alt="ifood">
                <span><u>Ver cardápio no ifood</u></span>
            </a>
        </div>

        <div class="col-md-12 mt-4">
            <img :src="require('@/assets/img/outros/manutencao.webp')" style="width: 300px" alt="manutencao">
        </div>
    </div>
</template>

<script>
export default {

}
</script>

<style>

</style>