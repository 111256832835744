import { createApp } from 'vue'
import App from './App.vue'
import Echo from 'laravel-echo';
import Pusher from 'pusher-js';
import router from './router'
import store from './store'
import PrimeVue from 'primevue/config';
import VueSweetalert2 from 'vue-sweetalert2';
import ToastService from 'primevue/toastservice';
import BadgeDirective from 'primevue/badgedirective';
import axios from 'axios';
import { checkStatus } from './utils/checkStatus';
import VueTheMask from 'vue-the-mask'
import VueConfetti from 'vue-confetti'
import bottomNavigationVue from "bottom-navigation-vue";
import Tooltip from 'primevue/tooltip';

// naive components
import naive from 'naive-ui'    

import 'primevue/resources/themes/aura-light-green/theme.css'
import 'primeicons/primeicons.css'
import '/node_modules/primeflex/primeflex.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap';
import 'sweetalert2/dist/sweetalert2.min.css';
import '@/assets/css/style.css';
import '@/assets/css/badge.css';
import "bottom-navigation-vue/dist/style.css";

const pusherKey = '7252079d713546876af3';
const pusherCluster = 'sa1';

window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: pusherKey,
    cluster: pusherCluster,
    forceTLS: true
});

const axiosInstance = axios.create({
    baseURL: 'https://projetopix.lksoftware.com.br/public/api/',
    // baseURL: 'http://127.0.0.1:8000/api/',
    headers: {
        'Content-Type': 'application/json',
    },
});

const app = createApp(App)
app.config.globalProperties.axios = axiosInstance;
app.config.globalProperties.checkStatus = checkStatus;
app.use(PrimeVue);
app.use(store)
app.use(VueSweetalert2)
app.use(router)
app.use(ToastService);
app.use(VueTheMask);
app.use(VueConfetti);
app.use(bottomNavigationVue);
app.use(naive)
app.directive('badge', BadgeDirective);
app.directive('tooltip', Tooltip);
app.mount('#app')