<template>
   <!-- Este cardápio está desativado, estamos atendendo somente pelo ifood! <a class="text-primary font-bold" href="https://www.ifood.com.br/delivery/uberlandia-mg/rei-do-pao-de-alho---lanche-presidente-roosevelt/a2b38159-0617-4230-adbf-3086f82571c7">Clique Aqui</a> e seja redirecionado para nosso cardápio -->
   <router-view />
   <!-- <Manutencao /> -->
</template>

<script>
import Manutencao from "@/components/Manutencao.vue";
export default {
   components: {
      Manutencao,
   },
};
</script>

<style>
#app {
  height: 100vh;
}

a {
   text-decoration: none !important;
   color: #000 !important;
}

.p-tabview-title {
   color: #10b981 !important;
}

.p-radiobutton-box .p-radiobutton-icon {
    background: #f4a403 !important;
}

.p-radiobutton.p-highlight .p-radiobutton-box {
    border-color: #f4a403 !important;
}

.p-message {
  margin: 5px;
  width: 90%;
}

ul,
li {
   padding-left: 5px !important;
}

.p-accordion .p-accordion-header:not(.p-disabled).p-highlight .p-accordion-header-link {
   color: #10b981 !important;
}

.p-accordion
   .p-accordion-header:not(.p-highlight):not(.p-disabled):hover
   .p-accordion-header-link {
   color: #000 !important;
}

.p-scrolltop {
   bottom: 65px !important;
}
</style>
